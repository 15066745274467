<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <!-- Display your invoice data here -->
      <div class="row gy-5 gx-xl-8 me-2">
        <div class="col-xxl-6">
          <div class="card">
            <!--begin::Header-->
            <div class="card-header align-items-center border-0 mt-4">
              <h2 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark"
                  >{{ $gettext("Invoice Overview") }} (#{{
                    data.invoiceNum
                  }})</span
                >
              </h2>
              <div
                class="btn btn-primary"
                @click="downloadPDF(data.invoiceNum, $event)"
              >
                <span class="indicator-label">
                  <span class="svg-icon svg-icon-1">
                    <inline-svg src="media/icons/duotone/Files/Download.svg" />
                  </span>
                </span>
                <span class="indicator-progress">
                  <span class="spinner-border spinner-border-sm me-3"></span>
                </span>
                PDF
              </div>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-5">
              <div class="table-responsive">
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px"
                >
                  <tbody class="fw-semibold text-gray-600">
                    <tr>
                      <td class="text-bolder">
                        <div class="d-flex align-items-center">
                          <span class="svg-icon svg-icon-3 fs-2 me-2">
                            <inline-svg
                              src="media/icons/duotone/Interface/Calendar.svg"
                            />
                          </span>
                          {{ $gettext("Issue Date") }}
                        </div>
                      </td>
                      <td class="fw-bold text-end">
                        {{
                          new Intl.DateTimeFormat(gettext.current, {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(data.issueDate)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bolder">
                        <div class="d-flex align-items-center">
                          <span class="svg-icon svg-icon-3 fs-2 me-2">
                            <inline-svg
                              src="media/icons/duotone/Shopping/Wallet.svg"
                            />
                          </span>
                          {{ $gettext("Transactional Account") }}
                        </div>
                      </td>
                      <td class="fw-bold text-end">
                        {{ data.trr }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bolder">
                        <div class="d-flex align-items-center">
                          <span class="svg-icon svg-icon-3 fs-2 me-2">
                            <inline-svg
                              src="media/icons/duotone/Shopping/Money.svg"
                            />
                          </span>
                          {{ $gettext("Grand Total") }}
                        </div>
                      </td>
                      <td class="fw-bold text-end">
                        {{ data.total }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--end::Table-->
              </div>
            </div>
            <!--end: Card Body-->
          </div>
        </div>
        <div class="col-xxl-6">
          <div class="card">
            <!--begin::Header-->
            <div class="card-header align-items-center border-0 mt-4">
              <h2 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark">{{
                  $gettext("Recipient Details")
                }}</span>
              </h2>
            </div>
            <!--:end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-5">
              <div class="table-responsive">
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px"
                >
                  <tbody class="fw-semibold text-gray-600">
                    <tr>
                      <td class="text-bolder">
                        <div class="d-flex align-items-center">
                          <span class="svg-icon svg-icon-3 fs-2 me-2">
                            <inline-svg
                              src="media/icons/duotone/Communication/Contact1.svg"
                            />
                          </span>
                          {{ $gettext("Customer") }}
                        </div>
                      </td>
                      <td class="fw-bold text-end">
                        {{ data.customer.name }} {{ data.customer.surname }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bolder">
                        <div class="d-flex align-items-center">
                          <span class="svg-icon svg-icon-3 fs-2 me-2">
                            <inline-svg
                              src="media/icons/duotone/Shopping/Wallet.svg"
                            />
                          </span>
                          {{ $gettext("Email") }}
                        </div>
                      </td>
                      <td class="fw-bold text-end">
                        {{ data.customer.email }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--end::Table-->
              </div>
            </div>
            <!--end: Card Body-->
          </div>
        </div>
      </div>
      <div class="row gy-5 gx-xl-8 me-2 pt-8">
        <div class="col-xxl-6">
          <div class="card">
            <!--begin::Header-->
            <div class="card-header align-items-center border-0 mt-4">
              <h2 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark">{{
                  $gettext("Recipient Address")
                }}</span>
              </h2>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-0">
              {{ data.customer.name }} {{ data.customer.surname }}, <br />
              {{ data.customer.address }}
              {{ data.customer.addressNumber }},<br />
              {{ data.customer.city }} {{ data.customer.zip }},<br />
              {{ data.customer.country }}
            </div>
            <!--end: Card Body-->
          </div>
        </div>
        <div class="col-xxl-6">
          <div class="card">
            <!--begin::Header-->
            <div class="card-header align-items-center border-0 mt-4">
              <h2 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark">{{
                  $gettext("Issuer Address")
                }}</span>
              </h2>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-0">
              {{ data.issuer.name }} {{ data.issuer.surname }}, <br />
              {{ data.issuer.address }} {{ data.issuer.addressNumber }},<br />
              {{ data.issuer.city }} {{ data.issuer.zip }},<br />
              {{ data.issuer.country }}<br />
              VAT no.: {{ data.issuer.VAT }}
            </div>
            <!--end: Card Body-->
          </div>
        </div>
      </div>
      <div class="row gy-5 gx-xl-8 me-2 pt-8">
        <div class="card">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h2 class="card-title align-items-start flex-column">
              <span class="fw-bolder mb-2 text-dark"
                >{{ $gettext("Invoice Details") }} (#{{
                  data.invoiceNum
                }})</span
              >
            </h2>
          </div>
          <!--end::Header-->

          <!--begin::Body-->
          <div class="card-body pt-5">
            <div class="card-body pt-0">
              <div class="table-responsive">
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-dashed fs-6 gy-5 mb-0"
                >
                  <thead>
                    <tr
                      class="text-start text-gray-600 fw-bold fs-7 text-uppercase gs-0"
                    >
                      <!--<th class="min-w-175px">{{ $gettext("PACKET ID") }}</th>
                <th class="min-w-100px text-end">{{ $gettext("MU") }}</th>
                <th class="min-w-70px text-end">{{ $gettext("Qty") }}</th>
                <th class="min-w-100px text-end">{{ $gettext("Unit Price") }}</th>
                <th class="min-w-100px text-end">{{ $gettext("Total") }}</th>-->
                      <th>
                        <div class="ms-5">{{ $gettext("Name") }}</div>
                      </th>
                      <th class="">{{ $gettext("Qty") }}</th>
                      <th class="text-end">{{ $gettext("Price w/ VAT") }}</th>
                    </tr>
                  </thead>
                  <tbody class="fw-semibold text-gray-600">
                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <!--begin::Title-->
                          <div>
                            <div class="fs-3 fw-bolder text-dark">
                              {{ $gettext("My orders") }}
                            </div>
                          </div>
                          <!--end::Title-->
                        </div>
                      </td>
                    </tr>
                    <template
                      v-for="(packet, index) in data.myPackets"
                      :key="index"
                    >
                      <tr>
                        <td>
                          <div class="d-flex align-items-center">
                            <!--begin::Title-->
                            <div class="ms-5">
                              <div class="fw-bold text-dark">
                                {{ $gettext("Packet id") }}:
                                {{ packet.packetId }}
                              </div>
                              <template
                                v-for="(datetime, jndex) in packet.datetime"
                                :key="jndex"
                              >
                                <div class="fs-7 text-gray-600">
                                  {{ $gettext("Date") }}:
                                  {{ datetime.value.date }}
                                  {{ datetime.value.time }}
                                </div>
                              </template>

                              <div class="fs-7 text-gray-600">
                                {{ $gettext("Price") }}:
                                {{ packet.price }}
                              </div>
                              <div class="fs-7 text-gray-800">
                                {{ packet.payment }}
                              </div>
                            </div>
                            <!--end::Title-->
                          </div>
                        </td>
                        <td class="text-dark"></td>
                        <td class="text-dark text-center">
                          {{ packet.total }}
                        </td>
                      </tr>
                      <template
                        v-for="(item, index) in packet.prices"
                        :key="index"
                      >
                        <tr>
                          <td class="text-center">
                            {{ item.title }}
                          </td>
                          <td>
                            {{ item.quantity }}
                          </td>
                          <td></td>
                          <td text="text-center">
                            {{ item.total }}
                          </td>
                        </tr>
                      </template>
                    </template>
                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <!--begin::Title-->
                          <div>
                            <div class="fs-3 fw-bolder text-dark">
                              {{ $gettext("Printee orders") }}
                            </div>
                          </div>
                          <!--end::Title-->
                        </div>
                      </td>
                    </tr>
                    <template
                      v-for="(packet, index) in data.printeePackets"
                      :key="index"
                    >
                      <tr>
                        <td>
                          <div class="d-flex align-items-center">
                            <!--begin::Title-->
                            <div class="ms-5">
                              <div class="fw-bold text-dark">
                                {{ $gettext("Packet id") }}:
                                {{ packet.packetId }}
                              </div>
                              <template
                                v-for="(datetime, jndex) in packet.datetime"
                                :key="jndex"
                              >
                                <div class="fs-7 text-gray-600">
                                  {{ $gettext("Date") }}:
                                  {{ datetime.value.date }}
                                  {{ datetime.value.time }}
                                </div>
                              </template>
                              <div class="fs-7 text-gray-600">
                                {{ $gettext("Price") }}:
                                {{ packet.price }}
                              </div>
                              <div class="fs-7 text-gray-800">
                                {{ packet.payment }}
                              </div>
                            </div>
                            <!--end::Title-->
                          </div>
                        </td>
                        <td class="text-dark"></td>
                        <td class="text-dark text-center">
                          {{ packet.total }}
                        </td>
                      </tr>
                      <template
                        v-for="(item, index) in packet.prices"
                        :key="index"
                      >
                        <tr>
                          <td class="text-center">
                            {{ item.title }}
                          </td>
                          <td>
                            {{ item.quantity }}
                          </td>
                          <td class="text-center">
                            {{ item.total }}
                          </td>
                        </tr>
                      </template>
                    </template>
                    <tr>
                      <td colspan="2" class="text-end">
                        <div class="fs-3 fw-bolder text-dark text-end">
                          {{ $gettext("Overview") }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="fs-3 text-dark text-end">
                        {{ $gettext("Grand Total") }}
                      </td>
                      <td class="text-dark fs-3 fw-bolder text-center">
                        {{ data.total }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">Subtotal</td>
                      <td class="text-center">
                        {{ data.subtotal }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">
                        VAT ({{ data.vatPercent }}%)
                      </td>
                      <td class="text-center">
                        {{ data.vat }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--end::Table-->
              </div>
            </div>
          </div>
          <!--end: Card Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useGettext } from "vue3-gettext";

import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { processAmount } from "@/core/helpers/utils";

export default defineComponent({
  name: "invoice",
  data() {
    const gettext = useGettext();
    const store = useStore();

    return {
      id: null as string | string[] | null,
      loading: true,
      data: [],
      gettext,
      store,
    };
  },
  async created() {
    this.id = this.$route.params.id;
    this.store.dispatch(Actions.INVOICE, { id: this.id }).then(({ data }) => {
      console.log(data);
      processAmount(
        "total",
        data,
        this.store.state.CurrencyModule.currencies,
        data.currency,
        this.gettext.current
      );
      processAmount(
        "subtotal",
        data,
        this.store.state.CurrencyModule.currencies,
        data.currency,
        this.gettext.current
      );
      processAmount(
        "vat",
        data,
        this.store.state.CurrencyModule.currencies,
        data.currency,
        this.gettext.current
      );
      this.processPackets(data.myPackets, data.currency);
      this.processPackets(data.printeePackets, data.currency);

      this.data = data;
      this.loading = false;

      watch(
        () => this.gettext.current,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            processAmount(
              "total",
              data,
              this.store.state.CurrencyModule.currencies,
              data.currency,
              this.gettext.current
            );
            processAmount(
              "subtotal",
              data,
              this.store.state.CurrencyModule.currencies,
              data.currency,
              this.gettext.current
            );
            processAmount(
              "vat",
              data,
              this.store.state.CurrencyModule.currencies,
              data.currency,
              this.gettext.current
            );
            this.processPackets(data.myPackets, data.currency);
            this.processPackets(data.printeePackets, data.currency);
          }
        }
      );
    });
  },
  methods: {
    processPackets(packets: any, currency: string) {
      for (const packet of packets) {
        processAmount(
          "price",
          packet,
          this.store.state.CurrencyModule.currencies,
          currency,
          this.gettext.current
        );
        processAmount(
          "total",
          packet,
          this.store.state.CurrencyModule.currencies,
          currency,
          this.gettext.current
        );

        for (const price of packet.prices) {
          processAmount(
            "total",
            price,
            this.store.state.CurrencyModule.currencies,
            currency,
            this.gettext.current
          );
        }
      }
    },
    downloadPDF(invoiceNum: string, e: Event) {
      const submitButton = e.currentTarget as HTMLElement;
      console.log(submitButton);

      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.classList.add("disabled");
      (submitButton as HTMLButtonElement).disabled = true;
      this.store
        .dispatch(Actions.INVOICE_DOWNLOAD, {
          locale: this.gettext.current,
          invoiceNum,
        })
        .then((response) => {
          submitButton.removeAttribute("data-kt-indicator");
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          link.click();
          (submitButton as HTMLButtonElement).disabled = false;
          submitButton.classList.remove("disabled");
        });
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Invoice");
    });
  },
});
</script>
