
import { defineComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useGettext } from "vue3-gettext";

import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { processAmount } from "@/core/helpers/utils";

export default defineComponent({
  name: "invoice",
  data() {
    const gettext = useGettext();
    const store = useStore();

    return {
      id: null as string | string[] | null,
      loading: true,
      data: [],
      gettext,
      store,
    };
  },
  async created() {
    this.id = this.$route.params.id;
    this.store.dispatch(Actions.INVOICE, { id: this.id }).then(({ data }) => {
      console.log(data);
      processAmount(
        "total",
        data,
        this.store.state.CurrencyModule.currencies,
        data.currency,
        this.gettext.current
      );
      processAmount(
        "subtotal",
        data,
        this.store.state.CurrencyModule.currencies,
        data.currency,
        this.gettext.current
      );
      processAmount(
        "vat",
        data,
        this.store.state.CurrencyModule.currencies,
        data.currency,
        this.gettext.current
      );
      this.processPackets(data.myPackets, data.currency);
      this.processPackets(data.printeePackets, data.currency);

      this.data = data;
      this.loading = false;

      watch(
        () => this.gettext.current,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            processAmount(
              "total",
              data,
              this.store.state.CurrencyModule.currencies,
              data.currency,
              this.gettext.current
            );
            processAmount(
              "subtotal",
              data,
              this.store.state.CurrencyModule.currencies,
              data.currency,
              this.gettext.current
            );
            processAmount(
              "vat",
              data,
              this.store.state.CurrencyModule.currencies,
              data.currency,
              this.gettext.current
            );
            this.processPackets(data.myPackets, data.currency);
            this.processPackets(data.printeePackets, data.currency);
          }
        }
      );
    });
  },
  methods: {
    processPackets(packets: any, currency: string) {
      for (const packet of packets) {
        processAmount(
          "price",
          packet,
          this.store.state.CurrencyModule.currencies,
          currency,
          this.gettext.current
        );
        processAmount(
          "total",
          packet,
          this.store.state.CurrencyModule.currencies,
          currency,
          this.gettext.current
        );

        for (const price of packet.prices) {
          processAmount(
            "total",
            price,
            this.store.state.CurrencyModule.currencies,
            currency,
            this.gettext.current
          );
        }
      }
    },
    downloadPDF(invoiceNum: string, e: Event) {
      const submitButton = e.currentTarget as HTMLElement;
      console.log(submitButton);

      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.classList.add("disabled");
      (submitButton as HTMLButtonElement).disabled = true;
      this.store
        .dispatch(Actions.INVOICE_DOWNLOAD, {
          locale: this.gettext.current,
          invoiceNum,
        })
        .then((response) => {
          submitButton.removeAttribute("data-kt-indicator");
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          link.click();
          (submitButton as HTMLButtonElement).disabled = false;
          submitButton.classList.remove("disabled");
        });
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Invoice");
    });
  },
});
